import { motion } from "framer-motion";
import * as cx from "classnames/bind";
import { Button, Input, Loader, Select } from "components";
import InputContainer from "components/atoms/Input/InputContainer";
import { useRecipeLists, useSession } from "hooks";
import Image from "next/legacy/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import s from "styles/Gallery.module.scss";
import { imageUrl, uniqObjArray, translate } from "utils";
import urlSlug from "url-slug";
import { useRouter } from "next/router";

const ArticleCard = ({ article, className, ...props }) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { data: session, status } = useSession();
  const regex = /(<([^>]+)>)/gi;

  return (
    <motion.div
      className={cx("rowItem", className, s.JournalFeedItem)}
      key={article.id}
      {...props}
    >
      <Link
        itemProp="url"
        href={
          "/journal/" +
          urlSlug(article.categories[0].category.slug) +
          "/" +
          article.slug
        }
      >
        <div
          className={cx(
            "GalleryImageWrapper Rad ofh p0",
            s.GalleryImageWrapper,
          )}
        >
          <Image
            src={imageUrl(article.image_id || "")}
            layout="responsive"
            width={350}
            height={450}
            objectFit="cover"
            itemProp="image"
            alt={article.title}
          />
        </div>
        <header>
          <p>
            {article.categories.map((category, index) => (
              <Link
                href={"/journal/" + category.category.slug}
                key={category.id}
              >
                {translate(category.category, "name", locale)}
                {index !== article.categories.length - 1 && ", "}
              </Link>
            ))}
          </p>
          <h3 itemProp="name" className="title">
            {translate(article, "title", locale)}
          </h3>
          {article.excerpt && (
            <span>
              {translate(article, "excerpt", locale)
                .replace(regex, "")
                .slice(0, 75) + "..."}
            </span>
          )}
        </header>
      </Link>
    </motion.div>
  );
};

export default ArticleCard;
